<template>
	<!-- SKU管理 -->
	<div
	 :lang="$i18n.locale"
	 class="app-container">
	  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane :label="$t('ksaWhInventoryPage.inventoryList')" name="first">
				<InventoryList :activeName="activeName"></InventoryList>
			</el-tab-pane>
			<el-tab-pane :label="$t('ksaWhInventoryPage.inWarehouseList')" name="second">
				<InWarehouse :activeName="activeName"></InWarehouse>
			</el-tab-pane>
	  </el-tabs>
	</div>
</template>

<script>
	import InventoryList from './inventoryList.vue'
	import InWarehouse from './inWarehouse.vue'
	export default {
	  //import引入的组件需要注入到对象中才能使用
	  components: {
			InventoryList,
			InWarehouse
	  },
		data() {
		  //这里存放数据
		  return {
				activeName:'first',
				
			}
		},
		//方法集合
		methods: {
			handleClick(){
				
			}
			
		}
		
	}
</script>

<style scoped>
	.app-container {
		padding: 10px;
	}
</style>