<template>
  <div>
    <!-- 创建 -->
    <el-dialog
    :title="$t('cwhInboundList.checkCartonTitle')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="800px"
    :before-close="handleDialogClose"
    >
      <el-table :data="cartonList" ref="listTable" border style="width: 100%;font-size: 16px;"
      	v-loading="cartonLoading">
      	<el-table-column :label="$t('commonInfo.packageNo')" prop="packageAwbNo" min-width="240"
      		align="center"></el-table-column>
      	<el-table-column :label="$t('commonInfo.index')" prop="packageIndex" min-width="100"
      		align="center"></el-table-column>
      	<el-table-column :label="$t('commonInfo.statusColumn')" prop="status" min-width="100"
      		align="center">
      		<template slot-scope="{ row }">
      			<span>{{returnStatusName(row.status)}}</span>
      		</template>
      	</el-table-column>
      	<el-table-column :label="$t('commonInfo.inboundTime')" prop="inboundTime" align="center" min-width="180">
      	</el-table-column>
      </el-table>
      <div class="pageBottom">
      	<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      		:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
      		layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
			<el-footer style="height: 60px;text-align: right;">
				<el-button @click="handleDialogClose()">{{$t('commonInfo.dialogBtnClose')}}</el-button>
			</el-footer>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CheckInboundListCartonList',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
			rowId:{
				type:String,
				default:function(){
					return '';
				}
			}
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
					this.popup = newValue;
          if(newValue){
						this.queryParams.entryOrderNo = this.rowId;
            this.getCartonList();
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
				cartonLoading:false,
				queryParams:{
					limit:10,
					page:1,
					entryOrderNo:'',
				},
				total:0,
				cartonList:[],
      }
    },

    //方法集合
    methods: {
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getCartonList();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getCartonList();
			},
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
				this.cartonList = [];
				this.queryParams = {
					limit:10,
					page:1,
					entryOrderNo:'',
				}
        // 清除数据
				this.$parent.rowId = '';
        this.$parent.checkCartonDetailPopup = false;
      },
			// 获取箱列表
			async getCartonList(){
				this.cartonLoading = true;
				this.cartonList = [];
				const res = await this.$http.post("/toborder/cwh/package/pageByOrderNo", this.queryParams);
				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					this.cartonList = data;
					this.cartonLoading = false;
				} else {
					this.$message.error(res.msg);
					this.cartonLoading = false;
				}
			},
			// 返回状态名
			returnStatusName(status){
				let name = '';
				switch(status){
					case 100: name = this.$t('cwhInboundList.createdStatus'); break;
					case 150: name = this.$t('cwhInboundList.inboundingStatus'); break;
					case 200: name = this.$t('cwhInboundList.inboundedStatus'); break;
					case 300: name = this.$t('cwhInboundList.pendingOutboundStatus'); break;
					case 350: name = this.$t('cwhInboundList.outboundingStatus'); break;
					case 400: name = this.$t('cwhInboundList.outboundedStatus'); break;
				}
				return name;
			},
			
    },
    created() {
    },

  }
</script>

<style scoped>
</style>
