<template>
  <div>
    <!-- 创建 -->
    <el-dialog
    :title="$t('cwhInboundList.createTitle')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="600px"
    :before-close="handleDialogClose"
    >
      <el-form ref="createForm" :model="createForm" :inline="true" :rules="rules"
      	label-width="120px">
				<el-form-item
					:label="$t('commonInfo.packageAmount')"
					prop="packageAmount">
					<el-input v-model.number="createForm.packageAmount"
						:style="{'width': '355px'}"
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageAmount')">
					</el-input>
				</el-form-item>
				<el-form-item
					:label="$t('commonInfo.remark')"
					prop="remarks">
					<el-input v-model="createForm.remarks"
				    type="textarea"
						:style="{'width': '355px'}"
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.remark')">
					</el-input>
				</el-form-item>
      </el-form>
			<el-footer style="height: 60px;text-align: right;">
				<el-button :disabled="submitLoading" @click="handleDialogClose()">{{$t('commonInfo.dialogBtnClose')}}</el-button>
				<el-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="handleSubmit">
					{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</el-footer>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CreateList',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      }
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
					this.popup = newValue;
          if(newValue){
            
          }
      	},
      	immediate: true,
      },
    },
		computed: {
			rules() { // 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
			  return {
			  	packageAmount: [{
			  		required: true,
			  		message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.packageAmount'),
			  		trigger: 'blur'
			  	}]
				}
			}
		},
    data() {
      return {
        popup:false,
				submitLoading:false,
        createForm:{
					packageAmount:'',
					remarks:'',
				},
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
				this.createForm = {
					packageAmount:'',
					remarks:'',
				}
        // 清除数据
        this.$parent.createPopup = false;
      },
			
			// 提交按钮
			handleSubmit(){
				this.$refs["createForm"].validate(valid => {
					if (valid) {
						this.submitCreateData();
					}
				})
			},
			// 提交创建
			async submitCreateData(){
				this.submitLoading = true;
				const res = await this.$http.post("/toborder/cwh/entry/create",this.createForm);
				if (res.code === 200) {
					this.$message.success(this.$t('tipsInfo.success'));
					this.$parent.getList();
					this.submitLoading = false;
					this.handleDialogClose();
				} else {
					this.submitLoading = false;
					this.$message.error(res.msg);
				}
			}
    },
    created() {
    },

  }
</script>

<style scoped>
</style>
