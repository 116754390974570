<template>
	<!-- 仓储费明细 - SKU -->
	<div class="app-container"
	:lang="$i18n.locale">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane label="SKU" name="first">
				<skuList :activeName="activeName"></skuList>
			</el-tab-pane>
			<el-tab-pane :label="$t('commonInfo.carton')" name="second">
				<cartonList :activeName="activeName"></cartonList>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	import skuList from './skuList.vue'
	import cartonList from './cartonList.vue'
	export default {
		name:'StorageChargeDetail',
		components: {
			skuList,
			cartonList
		},
		data() {
			return {
				activeName:'first',
			}
		},
		//方法集合
		methods: {
			handleClick(){
				
			}
		},
		created() {
			
		},
	}
</script>

<style scoped>
	.app-container {
		padding: 10px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
	}
</style>