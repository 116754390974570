// 该文档为VIP国际化-中文
const zh = {
	// 提示信息
	tipsInfo:{
		noData:'暂无数据',
		uploading:'上传中',
		uploadExcelTypeTips:'只能上传.xls或.xlsx文件格式',
		uploadSuccess:'上传成功',
		uploadFailed:'上传失败',
		loadingText:'加载中...',
		generating:'生成中',
		exportSuccess:'导出成功',
		downloadSuccess:'下载成功',
		exportCancel:'取消导出',
		exportFailed:'导出失败',
		downloadTips:'正在下载，请稍后',
		loginOutTips:'登录超时，请重新登录',
		errorPassword:'密码错误，请重新输入',
		noPodInfo:'暂无POD信息',
		// 输入框或者选择框提示信息 与填写表单文字和输入选择框的placeholder结合使用
		inputPlaceholder:'请输入',
		selectPlaceholder:'请选择',
		nullPlaceholder:'不能为空',
		inputTips:'请输入信息',
		
		orderNoExistTips:'输入的订单号不存在',
		createOrderNoFailedTips:'订单号获取失败',
		//删除
		deleteRowTips:'是否确认删除当前行数据?',
		deleteAllRowTips:'是否删除下方列表所有商品',
		deleteSuccessTips:'删除成功',
		
		copyTips:'复制当前列信息',
		copySuccess:'复制成功',
		copyFail:'复制失败',
		
		submitOrderFBXFileTips:'请先上传FBX附件',
		submitOrderPDFFileTips:'请先上传PDF附件',
		submitOrderDeliveryFileTips:'请先上传送仓附件',
		submitOrderDeliveryMarkTips:'请先上传送仓箱唛',
		submitOrderSkuListFileTips:'请先上传清单附件',
		submitOrderPacketFileTips:'请先上传转单小包附件',
		
		selectOrderTips:'请先勾选订单',
		selectCartonTips:'请先勾选箱',
		importTips:'选择文件导入数据',
		openDialogTips:'打开操作窗口',
		selectRowTips:'选择表格数据操作',
		
		exportDatabySearch:'根据搜索条件导出数据',
		uploadFileNameLimitTips:'上传文件名称过长，请重新上传',
		
		
		tip:'提示',
		warning:'警告',
		info:'信息',
		success:'成功',
		operationSuccess:'操作成功',
		failed:'失败',
		
	},
	// 通用信息
	commonInfo:{
		languageZh:'中文',
		languageEn:'英文',
		// 共用搜索条件按钮 
		searchBtn:'搜索',
		refreshBtn:'刷新',
		createBtn:'新增',
		addBtn:'添加',
		yesBtn:'是',
		noBtn:'否',
		deleteBtn:'删除',
		deleteAllBtn:'删除全部',
		upload:'上传',
		uploadAgain:'重新上传',
		uploadExcel:'上传Excel',
		beginTime:'开始时间',
		endTime:'结束时间',
		exportExcel:'导出Excel',
		total:'共有',
		secondTotal:'合计',
		case:'箱',
		time:'时间',
		choicePicture:'选择图片',
		city:'城市',
		noInfo:'暂无',
		action:'操作',
		checkFailedReason:'查看失败原因',
		reviewRemark:'审核备注',
		
		// 共用 表格列信息
		orderInfo:'订单信息',
		orderList:'订单列表',
		orderDeList:'订单详情列表',
		clientOrderNoColumn:'客户订单号',
		platformOrderNoColumn:'平台订单号',
		orderNoColumn:'订单号',
		subOrderNo:'子订单号',
		createOrder:'创建订单',
		createAirOrder:'创建空运订单',
		batchCreateAirOrder:'批量下单',
		createSeaOrder:'创建海运订单',
		modifyOrder:'修改订单',
		modifyAirOrder:'修改空运订单',
		modifySeaOrder:'修改海运订单',
		initTime:'下单时间',
		inboundTime:'入库时间',
		outboundTime:'出库时间',
		createTimeColumn:'创建时间',
		inventoryTime:'入库时间',
		deliveryTime:'出库时间',
		operationColumn:'操作',
		statusColumn:'状态',
		orderType:'订单类型',
		type:'类型',
		result:'结果',
		reason:'原因',
		link:'链接',
		photo:'图片',
		appointmentTime:'预约送仓时间',
		batchOperation:'批量操作',
		
		actionDetail:'查看详情',
		actionCheckReason:'查看原因',
		orderDetails:'订单详情',
		actiontrack:'查看轨迹',
		actionCheckFile:'查看附件',
		trackingTitle:'飞坦 轨迹追踪',
		trackLocation:'地点',
		actionCheckPOD:'查看POD',
		actionCheckCarton:'查看箱信息',
		actionCheckProduct:'查看商品',
		actionModify:'修改',
		actionModifyDeliveryTime:'修改送仓时间',
		actionDownload:'下载',
		faceNoodles:'下载面单',
		awbGenerating:'面单生成中...',
		actionDownloading:'下载中',
		actionDownloadPDF:'下载PDF',
		downloadBoxStickers:'下载箱贴',
		boxStickersGenerating:'箱贴生成中...',
		actionDownloadSkuList:'下载清单',
		actionDownloadSeaSkuTemplate:'下载海运清单模板',
		downloadTemplate:'下载模板',
		downloadClearanceFile:'下载清关面单',
		newLabelFile:'换标文件',
		orderTrack:'订单轨迹信息',
		moreAction:'更多操作',
		viewMore:'查看更多',
		
		
		//订单常用信息
		packageNo:'箱号',
		index:'序号',
		dlvdPackageNo:'飞坦箱号',
		length:'长度',
		breadth:'宽度',
		height:'高度',
		weight:'重量',
		weightKG:'重量(KG)',
		packageAmount:'箱数量',
		packageWeight:'包裹重量',
		grossWeight:'毛重',
		retreatWeight:'退货重',
		secondWeight:'净重',
		chargeWeight:'计费重',
		chargeVolume:'体积',
		chargeVolumeCbm:'体积(CBM)',
		chargeVolumeCcm:'体积(CCM)',
		productAmount:'商品总量/箱',
		memo:'备注',
		remark:'备注',
		consignor:'发货人',
		consignee:'收货人',
		contactMan:'联系人',
		contact:'联系人电话',
		address:'联系地址',
		deliveryAddress:'收件地址',
		deliveryPincode:'收件邮编',
		payMentMode:'支付类型',
		amountToCollect:'支付金额',
		city:'城市',
		country:'国家',
		deliveryWarehouse:'送仓仓库',
		cnConsignorWhHouse:'发货仓库',
		warhouseCode:'仓库代码',
		customeWarehouse:'自定义海外仓',
		deliveryCountry:'送仓国家',
		deliveryType:'派送类型',
		deliveryMode:'派送方式',
		homeDeliveryService:'送货上门',
		whSelfPickup:'仓库自提',
		selfPickup:'自提',
		consigneeTypeOne:'提货人',
		consigneeContact:'提货人电话',
		deliveryDest:'送仓地址',
		fbaNo:'FBA号',
		asnNo:'ASN号',
		clientDeliveryNo:'客户送仓号',
		deliveryDate:'预约送仓时间',
		pickupDate:'提货时间',
		fleetanAppointment:'飞坦预约中',
		expectedDeliveryQuantity:'期望送仓数量',
		fbaNoCode:'FBA号追踪编码',
		fbaNoCodeLimit:'长度为8位',
		fbaNoLimit:'长度为12位',
		clientAwbNoLimit:'最大长度为20位',
		companyLimit:'长度为5位或6位',
		skuCount:'商品总件数',
		packageCount:'包裹总件数',
		declaredValue:'申报总金额',
		fleetanWarehouse:'飞坦海外仓',
		skuStockType:'产品上架',
		cartonStockType:'整箱上架',
		carton:'整箱',
		count:'数量',
		batchNo:'批次号',
		batch:'批次',
		batchProductCount:'实际到货数量',
		productCount:'SKU数量',
		pcsCount:'PCS数量',
		actualCarton:'实际箱数',
		uploadFBAPDF:'上传箱唛',
		companyId:'店铺ID',
		currency:'币种',
		
		// 商品信息
		productInfo:'商品信息',
		clientProductCode:'客户商品编码',
		productCode:'系统商品编码',
		productName:"商品名称",
		productCnName:'商品中文名称',
		productEnName:'商品英文名称',
		productReviewFlag:'是否审核',
		productReviewed:'已审核',
		productUnreviewed:'未审核',
		productPrice:'商品价格',
		productQuantity:'库存数量',
		orderQuantity:'下单数量',
		appointmentQuantity:'预约数量',
		productLength:'商品长度',
		productBreadth:'商品宽度',
		productHeight:'商品高度',
		productSpec:'规格型号',
		unit:'单位',
		barcode:'自定义条码',
		
		
		skuFile:'清单文件',
		uploadFileName:'上传附件',
		uploadSkuFileName:'上传清单',
		fileLimitExcel:'只能上传xls/xlsx文件',
		fbxFileTips:'上传FBX附件-只能上传PDF文件',
		labelFileTips:'上传送仓箱唛-只能上传PDF文件',
		deliveryFileTips:'送仓文件-只能上传PDF文件',
		barcodeFileTips:'客户二维码和仓库二维码-只能上传PDF文件',
		newLabelFileTips:'上传换标文件-只能上传PDF文件',
		newLabelFileNolimitTips:'上传换标文件',
		pdfFileTips:'上传PDF附件-只能上传PDF文件',
		skuFileTips:'上传清单附件-只能上传xls/xlsx文件',
		sortSkuFileTips:'上传分拣送货清单附件-只能上传xls/xlsx文件',
		
		numberAndLetterLimit:'只能输入数字和英文字母',
		numberLimit:'只能输入数字和英文字母、-、_',
		numberToFixedOneLimit:'请输入数字，可保留一位小数',
		numberToFixedTwoLimit:'请输入数字，可保留两位小数',
		positiveIntegerLimit:'只能输入正整数',

		FBATips:'以FBA开头的12位FBA号',
		FBAExample:' 例如：FBA15G95TKZV',
		
		productionStatusOne:'已登记',
		productionStatusTwo:'分配库位',
		productionStatusThree:'预约入库',
		productionStatusFour:'已入库',
		
		// RTO 
		destory:'销毁',
		destorying:'销毁中',
		destoryCompleted:'已销毁',
		inventory:'上架',
		inventorying:'上架中',
		inventoryCompleted:'已上架',
		transfer:'转单',
		skuStock:'产品上架',
		waitForOrder:'等待操作指令',
		processing:'处理中',
		completed:'已完成',
		
		
		// 共用 窗口信息
		dialogBtnConfirm:'确认',
		dialogBtnSubmit:'提交',
		dialogBtnSubmiting:'提交中',
		dialogBtnCancel:'取消',
		dialogBtnClose:'关闭',
		dialogBtnModify:'修改',
		dialogBtnReset:'重置',

		cancelProductCount:'退货数量',

		transportType:'头程运输方式',
		operationTime:'操作时间',
		air:'空运',
		sea:'海运',
		local:'本地',
		
	},
	// 系统设置 /menu.vue
	setting:{
		languageType:'中文',
		changePasswordOption:'修改密码',
		loginOutOption:'退出登录',
	},
	// 菜单列表 /menu.vue
	menuItem:{
		homeItem:'首页',
		airOrderItem:{
			name:'空运订单',
			b2bOrder:'B2B订单',
			b2cOrder:'B2C订单',
		},
		seaOrderItem:{
			name:'海运订单',
			bulkCargoOrder:'海运散货订单',
			
		},
		
		platformOrderItem:{
			name:'平台订单',
			aliExpress:'速卖通',
		},
		chinaWhItem:{
			name:'中国仓直邮',
			b2cOrder:'B2C订单',
			b2bOrder:'B2B订单',
		},
		ksaWhItem:{
			name:'沙特仓履单',
			product:'商品管理',
			batch:'批次管理',
			inventory:'库存管理',
			skuManage:'商品管理',
			cartonManage:'整箱管理',
			
			inscanLog:'商品入库记录',
			outscanLog:'商品出库记录',
			fbxOrder:'FBX订单',
			b2cOrder:'B2C订单',
			operationLogs:'操作日志',
			inscanLogs:'入库日志',
			outscanLogs:'出库日志',
			inboundLogs:'上架日志',
			outboundLogs:'下架日志',
		},
		rtoItem:{
			name:'退仓管理',
			rtoB2cOrder:'B2C订单',
			rtoFbxOrder:'FBX订单',
			rtoDamage:'损坏商品',
		},
		invoiceManageItem:{
			name:'账单管理',
			storageChargeItem:{
				name:'仓储费',
				
				chargeDetail:'费用明细',
			}
		},
		trackingItem:'轨迹查询',
		clientInfoItem:'客户信息',
		templateDownloadItem:'模板下载',
		
		// CWH
		cwhInboundListItem:'入库单',
		cwhInventoryListItem:'库存列表',
		cwhOutboundListItem:'出库单',
		
	},
	//登录页面 /login.vue
	loginPage:{
		loginName_ph:'请输入用户名',
		loginPwd_ph:'请输入密码',
		loginErrorInfo:'用户名或密码不正确，请重新输入',
		loginSuccessInfo:'登录成功',
		loginOtherError:'请输入正确的用户名，密码',
		loginBtn:'登录',
		systemPoweredBy:'技术支持',
	},
	// 首页页面 、home.vue
	homePage:{
		homeTitle:'最新公告',
		homeUpdateTime:'发布时间',
	},
	// 修改密码 /B2B/changePass.vue
	changePassPage:{
		changePassTitle:'修改密码',
		oldPassword:'旧密码',
		newPassword:'新密码',
		confirmPassword:'确认密码',
		confirmPassword_ph:'请再次输入新密码',
		sameOldAndNewPasswordTips:'新密码不能与旧密码相同！',
		diffNewPasswordTips:'与新密码不一致！',
		newPasswordLimit:'最小输入5位,最多输入16位',
		cancelAndReload:'已取消修改，请重新登陆',
		nullPasswordTips:'新、旧密码不能为空',
	},
	// 客户信息
	clientInfoPage:{
		pageTitle:'客户信息',
		clientFullName:'客户全称',
		clientShortName:'客户简称',
		clientEmail:'邮箱',
		clientPrefixNo:'客户代码',
		wcServiceAccount:'Fleetan 微信服务号',
		clickAndCopy:'点击复制',
	},
	// 模板下载页面
	mouldListPage:{
		pageTitle:'模板下载列表',
		templateName:'名称',
		templateUrl:'下载地址',
	},
	// 平台订单
	platformOrder:{
		uploadOrderList:'导入订单列表',
		aliExpressList:'速卖通订单列表',
	},
	// 中国仓库 B2C订单页面 /B2COrderList.vue
	chinaWhB2COrderPage:{
		listTabName:'列表',
		B2ClistTabName:'B2C列表',
		listTabSearchItemAWB_ph:'飞坦 空运单号',
		listTabSearchItemPaymentMode_ph:'支付方式',
		listTabOperationMultipleDownloadAwb:'批量下载面单',
		//表格信息
		listTabTableColumnAwb:'空运单号',
		listTabTableColumnImileAwb:'Imile 单号',
		listTabTableColumnDestCity:'目的城市',
		listTabTableColumnPaymentMode:'支付方式',
		// 表格操作
		listTabTableActionDownloadAwb:'面单',
		downloadNoodles:'下载面单',
		listTabTableActionMessage:'留言',
		messageInformation:'留言信息',
		listTabTableActionWeight:'查看重量',
		squareWeighing:'量方称重',
		// 轨迹查询窗口
		dialogCheckTrackTitle:'订单轨迹',
		dialogCheckTrackNoData:'暂无轨迹信息',
		
		//订单详情窗口
		dialogDetailOrderInfo:'订单信息',
		dialogDetailAmountToCollect:'申报金额',
		dialogDetailProductDes:'商品描述',
		dialogDetailConsignorInfo:'发货人信息',
		dialogDetailConsigneeInfo:'收货人信息',
		dialogDetailProductDetail:'商品详情',
		dialogDetailProduct_productName:'商品名称',
		dialogDetailProduct_productLink:'商品链接',
		dialogDetailProduct_productHscode:'商品编码',
		dialogDetailProduct_productSku:'商品SKU',
		dialogDetailProduct_productPcs:'数量',
		dialogDetailProduct_productPrice:'商品价格',
		dialogDetailProduct_productTaxrate:'商品税率',
		// 留言板窗口
		dialogMessageTitle:'留言板',
		dialogMessageInputMessage:'请输入留言内容',
		dialogMessageSubmit:'发布',
		dialogMessageSubmitSuccess:'发布成功',
		// 重量信息窗口
		dialogWeightTitle:'重量信息',
		
		// 导入Tab页
		importTabName:'导入订单',
		B2CimportTabName:'B2C导入订单',
		importTabImportINTBtn:'导入INT订单',
		importTabImportDOMBtn:'导入DOM订单',
		importTabImportCDOMBtn:'导入CDOM订单',
		importTabImportFDOMBtn:'导入FDOM订单',
		importTabDownloadTemplateBtn:'下载模板',
		downloadINTTemplateBtn:'下载INT模板',
		downloadFDOMTemplateBtn:'下载FDOM模板',
		importTabTableColumnImportTime:'导入时间',
		importTabTableColumnOperator:'操作人',
		importTabTableColumnOrderCount:'订单数量',
		importTabTableColumnAction:'操作',
		importTabTableColumnActionExportOrder:'导出订单详情',
		importTabExportDataTips:'是否确认导出选中数据',
	},
  // 中国仓库 B2B订单页面 /B2BOrderList.vue
	chinaWhB2BOrderPage:{
		pageTitle:'B2B订单列表',
		actionCheckPackage:'确认重量',
		actionModifyFBA:'修改FBA',
		officialCD:'正式报关',
		unofficialCD:'非正式报关',
		generated:'已生成',
		boxes:'箱',
		expandAndHide:'点击展开/隐藏',
		
		//确认重量窗口
		dialogPackageInfoTitle:'重量信息',
		// 转单小包包裹信息窗口
		dialogPacketInfoTitle:'包裹信息',
		dialogPacketInfoPacketNo:'包裹号',
		dialogPacketInfoLink:'链接',
		dialogPacketInfoResult:'上传结果',
		customsDeclaration:'报关',
		customsDeclarationFlag:'是否报关',
		// 添加品名窗口
		dialogAddKindnameTitle:'添加品名',
		dialogAddKindname:'产品名称',
		dialogAddKindnameSubmitTips:'请补充品名中英文',
		kindnameDes:'产品描述',
		
		kindnameSelectTips:'请选择常用产品类型',
		// 修改FBA窗口
		dialogModifyFBANewFBAInfo:'新的FBA信息',
		dialogModifyFBANewFBATips:'请上传新的FBX附件',
		szDlvdWarehouseAddress:'深圳飞坦物流仓库地址',
		szDlvdWarehouseContact:'张林河',
		orderReviewTips:'订单审核中，请等待审核通过下载面单按要求粘贴后发货',
		orderSkuCountTips:'发货的总的产品数量，需要准确，FBA预约送仓，需要这个数量',
		clientOrderPackageAmount:'客户订单箱数',
		createBoxNoTips:'输入数字，可自动生成相应箱数',
		downloadPdfFailedTips:'订单面单生成中，请稍后刷新列表再下载',
		createClientPackageNoInputFBATips:'请先填写FBA号，箱号根据FBA号生成',
		createClientPackageNoInputASNTips:'请先填写ASN号，箱号根据ASN号生成',
		submitOrderProductionTips:'请选择或添加产品名称',
		submitOrderInputPackageNumTips:'请填写正确的包裹总数和申报金额',
		submitOrderInputSkuNumberTips:'请填写正确的SKU总数和申报金额',
		submitOrderInputClientPackageNumTips:'请输入客户订单箱数',
		uploadPacketFileDiffNumTips:'获取附件包裹数量与您填写的包裹总件数不一致，请检查',
		
		generateBoxStickerTipsPre:'箱贴仍在生成中，但生成时间过长，您可以关闭该窗口等待',
		generateBoxStickerTipsNext:'分钟后刷新列表下载箱贴',
		deliveryDateTips:'请选择预约派送时间，与送仓文件时间一致',
		
		caseAutoFillTips:'该箱号信息填充到后续箱号信息',
		caseInputTips:'检测到箱号信息未填写，请先补充完整',
		Approved:'审核通过',
		underReview:'未审核',
		clientOrderNo:'客户订单号',
		
		// 海运订单多目的地信息
		destInfo:'目的地信息',
		seaOrderDestTitle:'订单目的地信息',
		destType:'目的地类型',
		clearAllDestInfoTips:'该操作将会清除所有的目的地信息，是否确认?',
		seaCartonIndex:'派送箱序号',
		cartonIndexTips:'请填写派送该目的地箱信息',
		cartonIndexStart:'开始箱序号',
		cartonIndexEnd:'截止箱序号',
		errorCartonIndex:'开始箱序号不能大于截止箱序号',
		overCartonAmountTips:'箱序号不能超过订单箱总数',
		inputCartonIndexTips:'请输入箱序号,例如: 1-5,7,9-10,12',
		inputCartonIndexNumTips:'箱序号范围起始不能大于结尾',
		repeatCartonTips:'订单目的地信息某些箱有多个派送地址，请检查',
		cartonTotalPcs:'总PCS',
		cartonTotalPcsTips:'请输入该目的地派送箱包含的总PCS',
		
		seaOrderFailedReason:'海运订单审核不通过详情',
		
		//分批轨迹
		batchTrackingTitle:'分批信息',
		
		containWoodOrBamboo:'含木含竹',
		shipType:'船类型',
		normalShip:'普船',
		fastShip:'快船',
		shipTypeTips:'快船价格比普船高，具体价格请询问业务员',
		
		inWhCount:'实际入库箱数',
		cancelCount:'取消箱数',
	},
	// 沙特仓库，商品列表
	ksaWhProductListPage:{
		pageTitle:'商品列表',
		exportProductDataBtn:'导出商品信息',
		multipleImportProductDataBtn:'批量导入商品',
		dialogProductAddTitle:'新增商品信息',
		dialogProductModifyTitle:'修改商品信息',
		exportAllProductTips:'导出所有商品信息',
		productUploadSuccessTips:'导入商品成功，系统正在审核解析商品中，请稍等',
		productUploadSuccessTips_title:'导入商品模板注意事项: ',
		productUploadSuccessTips_opt1:'1、图片非必填.',
		productUploadSuccessTips_opt2:'2、图片不要嵌入式，不能超出单元格',
		productAnalysis:'审核商品文件中...',
		productAnalysisFailed:'商品文件审核失败',
	},
	// 沙特仓库，批次列表
	ksaWhBatchListPage:{
		pageTitle:'批次列表',
		orderNoOrBatchNo:'订单号(批次号)',
		turnToSkuStock:'转为Sku上架',
		skuStockDes:'若该订单需要用到我司海外仓上架履单，或者送 NOON 的货物不是一整个订单一次派送则选择此种类型',
		cartonStockDes:'1.若由于 FBA 库容不足导致下单前无法获悉明确的 FBA 号,2.FBN 下单前无法创建 ASN 号，但是能明确该订单是整个订单一次性派送',
		turnToSkuTipOne:'提示:此操作会将该批次下',
		turnToSkuTipTwo:'所有',
		turnToSkuTipThree:'整箱上架产品转为按SKU上架,不支持部分转换',
	},
	// 沙特仓库，库存页面
	ksaWhInventoryPage:{
		tabOneTitle:'统计',
		tabOnePageTitle:'数据统计',
		cartonInfo:'整箱信息',
		currentCartonOrderCount:'当前整箱在库订单数量',
		currentCartonCount:'当前整箱在库数量',
		skuInfo:'产品信息',
		currentSkuCount:'当前SKU在库数量',
		currentProductCount:'当前商品在库数量',
		
		tabTwoTitle:'商品',
		tabTwoPageTitle:'商品库存列表',
		exportAllSkuData:'导出当前所有SKU库存信息',
		
		tabThreeTitle:'整箱',
		tabThreePageTitle:'整箱在库列表',
		exportCurrentAllCartonInventoryData:'导出当前所有整箱库存信息',
		putOnTime:'上架时间',
		storageTime:'存储时长(天)',
		
		inventoryList:'库存列表',
		inWarehouseList:'在库列表',
		productInWarehouseList:'商品在库列表',
		clientBatchCode:'客户批次编码',
		whName:'仓库名称',
		locationName:'库位',
		inventoryAmount:'在库数量',
		
		
	},
	// 沙特仓库 商品入库记录列表
	ksaWhInscanLogs:{
		pageCartonTitle:'整箱入库记录列表',
		pageCartonTitle2:'整箱上架记录列表',
	},
	// 沙特仓库 商品出库记录列表
	ksaWhOutscanLogs:{
		pageCartonTitle:'整箱出库记录列表',
		pageCartonTitle2:'整箱下架记录列表',
	},
	// 沙特仓库 上架日志
	ksaWhInboundLogs:{
		tabOne:'Sku',
		tabOneTitle:'商品入库记录列表',
		tabOneTitle2:'商品上架记录列表',
		inboundTime:'上架时间',
	},
	// 沙特仓库 下架日志
	ksaWhOutboundLogs:{
		tabOne:'Sku',
		tabOneTitle:'商品出库记录列表',
		tabOneTitle2:'商品下架记录列表',
		outboundTime:'下架时间',
	},
	
	// 沙特仓库 FBX订单列表页面
	ksaWhFBXOrderPage:{
		multipleImportProduct:'批量导入送仓商品',
		inputCorrectQuantity:'请输入正确数量',
		newLabelCode:'换标商品编码',
		newLabelQuantity:'换标数量',
		newSku:'新Sku',
		newSkuCount:'商品换标数量',
		cancelNumber:'取消数量',
		newLabelFileInfo:'换标文件信息',
		createOrderInfo:'新增订单信息',
		modifyOrderInfo:'修改订单信息',
		submitDeliveryTimeTips:'是否确认该订单送仓时间为',
		checkProductTips:'添加商品信息检测有未录入商品或重复商品，请查看列表商品是否缺失',
		deleteOrderTips:'是否删除该订单',
		uploadPreTips:'请先上传',
		selectProductTips:'请选择商品',
		overOrderCountTips:'输入的下单数量不能超过商品的库存数量',
		orderCountDiffTips:'检测到下单商品总数量和期望送仓数量不一致',
		orderCountTips:'下单商品总数量',
		confirmToCreateOrderTips:'请确认是否继续创建订单',
		checkProductErrorTips:'未检测到商品信息',
		urgentOrderTip:'该订单为加急单，需要收取加急费用',
	},
	// RTO B2C /RTO/RtoBind.vue
	rtoB2COrderPage:{
		tabOne:'待处理',
		tabOnePageTitle:'RTO B2C 列表',
		tabTwo:'已完成任务',
		
		multipleTransfer:'批量转单',
		multipleInventory:'批量上架',
		downloadTransferTemplate:'转单模板下载',
		multipleDestory:'批量销毁',
		remainingDay:'剩余操作时间(天)',
		operationTips:'存储时间超过30天,系统将自动销毁当前订单',
		operationOverTime:'超时已销毁',
		packetTransfer:'小包转单',
		confirmDestoryOrder:'是否确认销毁该订单?',
		confirmMultipleDestoryOrder:'是否确认执行批量销毁?',
		confirmInventoryOrder:'是否确认执行上架指令?',
		transferInfo:'转单信息',
		originOrderInfo:'原订单信息',
		confirmToTransferOrder:'是否确认执行转单操作?',
	},
	// RTO FBX /RTO/RtoFbx.vue
	rtoFbxOrderPage:{
		tabOne:'合格商品',
		tabOnePageTitle:'FBX 合格商品列表',
		tabTwo:'不合格商品',
		tabTwoPageTitle:'FBX 不合格商品列表',
		tabThree:'已完成列表',
		
		rtoCartonNo:'退货箱号',
		qcFailedProductInfo:'不合格商品信息',
		skuLabelDamage:'标签破损',
		changeSku:'换标',
		packageDamage:'包装破损',
		changeOrigin:'换原包装',
		changePO:'换PO袋',
		productDamage:'产品损坏',
		label:'标签',
		uploadLabel:'上传标签',
		downloadLabel:'下载标签',
		
		
		crossTime:'开箱时间',
		instructionTime:'下达指令时间',
		completeTime:'指令完成时间',
		crossCheck:'开箱验货',
		transferWh:'转仓',
		unpackingList:'开箱清单',
		checkPhoto:'查看图片',
		multipleTransferWh:'批量转仓',
		multipleCrossCheck:'批量开箱验货',
		multipleStockSku:'批量上架',
		transferWhInfo:'转仓信息',
		crossCheckSkuInfo:'开箱商品清单信息',
		qcQualified:'QC合格',
		qcUnQualified:'QC不合格',
		stockAll:'全部上架',
		destoryAll:'全部销毁',
		operationType:'操作类型',
		crossCheckCompleted:'验货完成，等待指令',
		crossCheckNewTask:'等待仓库执行开箱清单指令操作',
		crossCheckAllCompleted:'开箱操作后续处理完成',
		crossCheckTips:'是否执行开箱验货操作?',
		checkAllSkuSelected:'请确认所有SKU已选操作',
		qcFailedSkuTips:'QC不合格SKU和数量',
		multipleInventoryTips:'该操作将会把所有勾选的箱SKU执行上架操作，是否确认',
		
		inventoryTips:'QC不合格SKU需要先查看图片后才能执行上架操作，QC合格SKU将上架至正常库存，QC不合格SKU将上架至损坏库存',
		hasQcFailedRowTips:'已勾选箱信息检测到没有上传SKU或者有QC不合格SKU，不能执行批量上架操作，请在开箱清单列表中执行销毁或上架操作',
		isCompletedCrossCheckRowTips:'请选择未完成开箱验货后续操作箱数据',
		submitQcPassedDataTips:'请检查每个商品信息并下达操作指令',
		submitQcFailedDataTips:'请检查每个商品信息并下达操作指令,部分操作需要上传新的标签图片',
		checkStatusTips:'请勾选等待指令箱数据',
		
		selectStatusTips:'请选择处理状态',
		crossCheckTimeStartTips:'开箱完成时间起始',
		crossCheckTimeEndTips:'开箱完成时间截止',
		instructionTimeStartTips:'下达指令时间起始',
		instructionTimeEndTips:'下达指令时间截止',
		
	},
	// RTO Damage /RTO/RtoDamage.vue
	rtoDamagePage:{
		pageTitle:'损坏商品列表',
	},
	//轨迹查询
	trackQuery:{
		trackTitle:'轨迹查询',
		trackContent:'输入订单号',
		trackButton:'查询',
		NotExist:'输入的订单号不存在',
		messageImport:'请输入订单号'
	},
	//B2B状态
	B2BStatus:{
		creating:'创建中',
		init:'已下单',
		warehousing:'入库中',
		cnReceive:'入库完成',
		cnPartialShipment:'已分批',
		cnOutscan:'已出库',
		cnOutscanExceptionPart:'部分出库',
		cnTransitExceptionCheck:'过港查验',
		cnAir:'已起飞',
		cnTransitException:'已过港',
		cnTransitExceptionOr:'部分过港',
		cnAirExceptionDelay:'航班延误',
		cnAirExceptionCancel:'航班取消',
		airArrived:'已到港',
		customsClearanceCompLated:'已清关',
		customsClearanceComplatedExceptionCheck:'清关异常: 查验',
		customsClearanceComplatedExceptionDeduction:'清关异常: 扣货',
		overseasArrived:'已到达海外仓仓库',
		ksaInscan:'已到达海外仓仓库',
		cartonToSku:'转产品上架',
		ksaReadyForDispatch:'准备派送',
		delivering:'派送中',
		deliveryFailed:'派送失败',
		partialreceipt:'部分签收',
		successfulDelivery:'签收成功',
		signingFailed:'签收失败',
		cancelOrder:'取消订单',
		transferThirdpartyDelivery:'转第三方派送'
	},
	//B2C状态
	B2CStatus:{
		returned:'已退回',
		delivered:'签收成功',
		pending:'处理中'
	},
	//B2B订单
	B2BOrder:{
		//table页
		airTransportOrder:'空运订单',
		airTransportOrderList:'空运订单列表',
		oceanShippingOrder:'海运订单',
		oceanShippingOrderList:'海运订单列表',
		customerAwbNo:'客户入仓号',
		deCountry:'目的国家',
		deType:'服务类型',
		goodCnDesc:'货品中文描述',
		goodEnDesc:'货品英文描述',
		cnConsignorWh:'国内发货仓库',
		goodType:'货物类型',
		examineStatus:'审核状态',
		cartonInfo:'箱信息',
		orderNoRepeat:'客户订单号重复',
		succeededOceanOrder:'B2B海运订单修改成功', 
		addOceanOrder:'添加B2B海运订单成功', 
	},
	
	
	// CWH 
	// 入库单
	cwhInboundList:{
		title:'入库单列表',
		inboundNo:'入库单号',
		createTitle:'创建入库单',
		createdStatus:'创建完成',
		inboundingStatus:'入库中',
		inboundedStatus:'入库完成',
		pendingOutboundStatus:'待出库',
		outboundingStatus:'出库中',
		outboundedStatus:'出库完成',
		downloadInboundList:'下载入库单',
		checkCartonTitle:'入库单箱信息',
	},
	// 库存列表
	cwhInventoryList:{
		title:'在库箱列表',
		
	},
	// 出库单列表
	cwhOutboundList:{
		title:'出库单列表',
		createTitle:'创建出库单',
		refuseReason:'拒绝原因',
		refuseOutbound:'拒绝出库',
		OutboundNo:'出库单号',
		downloadOutboundList:'下载出库单',
		checkCartonTitle:'出库单箱信息',
		selectCartonTips:'请选择出库的箱数据',
		
	},
	// 账单管理-仓储费
	storageCharge:{
		title:'仓储费明细',
		freeStorageDay:'免租天数',
		startChargeDate:'开始计费日期',
		chargeDay:'计费天数',
		unitPrice:'单价',
		totalPrice:'计费金额',
	}
}
export default zh;