<template>
  <div>
    <!-- 创建 -->
    <el-dialog
    :title="$t('cwhOutboundList.createTitle')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="1000px"
    :before-close="handleDialogClose"
    >
      <el-form ref="queryParams" :model="queryParams" :inline="true"
      	label-width="90px">
				<el-form-item>
					<el-input v-model.trim="queryParams.packageNo" type="textarea"
						:style="{'width': '225px'}"
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageNo')">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" @click="handleSearch">
						{{$t('commonInfo.searchBtn')}}</el-button>
					<el-button icon="el-icon-refresh" type="primary" @click="getCartonList">
						{{$t('commonInfo.refreshBtn')}}</el-button>
					<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
					</el-button>
				</el-form-item>
      </el-form>
			<el-row>
				<span>已勾选箱数：{{selectionRow.length}}</span>
			</el-row>
			<el-table :data="cartonList" ref="cartonList" border style="width: 100%;font-size: 16px;"
				v-loading="loading" @selection-change="handleSelection" :row-key="getRowKey">
				<el-table-column type="selection" :reserve-selection="true" align="center" width="55"></el-table-column>
				<el-table-column :label="$t('cwhInboundList.inboundNo')" prop="entryOrderNo" min-width="160"
					align="center"></el-table-column>
				<el-table-column :label="$t('commonInfo.packageNo')" prop="packageAwbNo" min-width="200"
					align="center"></el-table-column>
				<el-table-column :label="$t('commonInfo.index')" prop="packageIndex" min-width="100"
					align="center"></el-table-column>
				<el-table-column :label="$t('commonInfo.inboundTime')" prop="inboundTime" align="center" min-width="180">
				</el-table-column>
			</el-table>
			<div class="pageBottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</div>
			<el-footer style="height: 60px;text-align: right;">
				<el-button :disabled="submitLoading" @click="handleDialogClose()">{{$t('commonInfo.dialogBtnClose')}}</el-button>
				<el-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="handleSubmit">
					{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</el-footer>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CreateList',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      }
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
					this.popup = newValue;
          if(newValue){
            this.getCartonList();
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
				loading:false,
				submitLoading:false,
				queryParams:{
					packageNo:'',
					packageStatus:200,// 在库
					packageNoList:[],
					page:1,
					limit:10,
				},
				cartonList:[],
				selectionRow:[],
				selectionCartonList:[],
				total:0,
        createForm:{
					packageNoList:[],
					remarks:'',
				},
      }
    },

    //方法集合
    methods: {
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getCartonList();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getCartonList();
			},
			// 获取唯一标识
			getRowKey(row) {
			  return row.id;
			},
			// 勾选数据
			handleSelection(val){
				this.selectionRow = val;
				this.selectionCartonList = val.map(item => item.packageAwbNo);
			},
			
			// 搜索
			handleSearch() {
				if(this.queryParams.packageNo != ''){
					this.queryParams.packageNoList = this.strToArr(this.queryParams.packageNo)
				}else{
					this.queryParams.packageNoList = [];
				}
				this.getCartonList();
			},
			// 重置
			handleReset() {
				this.queryParams = {
					page: 1,
					limit: 10,
					packageNo:'',
					packageStatus:200,
					packageNoList:[],
				}
				this.selectionRow = [];
				this.selectionCartonList = [];
				this.$refs.cartonList.clearSelection();
				this.getCartonList();
			},
			// 获取在库箱列表
			async getCartonList(){
				this.loading = true;
				this.cartonList = [];
				const res = await this.$http.post("/toborder/cwh/package/pageByUser", this.queryParams);
				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					this.cartonList = data;
					this.loading = false;
				} else {
					this.$message.error(res.msg);
					this.loading = false;
				}
			},
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
				this.createForm = {
					packageNoList:[],
					remarks:'',
				}
				this.selectionRow = [];
				this.selectionCartonList = [];
				this.cartonList = [];
				this.queryParams = {
					page: 1,
					limit: 10,
					packageNo:'',
					packageStatus:200,
					packageNoList:[],
				}
				this.$refs.cartonList.clearSelection();
				this.total = 0;
        // 清除数据
        this.$parent.createPopup = false;
      },
			
			// 提交按钮
			handleSubmit(){
				if(this.selectionRow.length == 0){
					return this.$message.warning(this.$t('cwhOutboundList.selectCartonTips'))
				}
				this.createForm.packageNoList = this.selectionCartonList;
				this.submitCreateData();
			},
			// 提交创建
			async submitCreateData(){
				this.submitLoading = true;
				const res = await this.$http.post("/toborder/cwh/out/create",this.createForm);
				if (res.code === 200) {
					this.$message.success(this.$t('tipsInfo.success'));
					this.$parent.getList();
					this.submitLoading = false;
					this.handleDialogClose();
				} else {
					this.submitLoading = false;
					this.$message.error(res.msg);
				}
			}
    },
    created() {
    },

  }
</script>

<style scoped>
</style>
