<template>
	<section class="app-main">
	  <transition name="fade-transform" mode="out-in">
	  	<keep-alive :max="20" :exclude="notCacheName">
	  		<router-view :key="key"></router-view>
	  	</keep-alive>
	  </transition> 
	</section>
</template>
<script>
	export default {
	  name: "ViewMain",
		// 获取父组件信息
		props: {
		  menuList:{
		  	type:Array,
		  	default:function(){
		  		return [];
		  	}
		  }
		},
		components:{
		},
		computed: {
		  // 不需要缓存的页面
		  notCacheName(){
		  	var noCacheViews = [];
		  	Object.keys(this.menuList).some(index => {
		  	  if (this.menuList[index].meta.noCache) {
		  	    noCacheViews.push(this.menuList[index].name);
		  	  }
		  	});
		  	return noCacheViews;
		  },
			key() {
			  return this.$route.path;
			}
		},
	  data() {
	    return {
	    };
	  },
	  methods: {
		}
	}
</script>

<style scoped>
	.app-main{
		height: calc(100vh - 60px);
		overflow-y: auto;
		overflow-x: hidden;
	}
</style>