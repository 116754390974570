<template>
	<!-- CWH 入库单 -->
	<div class="app-container"
	:lang="$i18n.locale"
	v-loading.fullscreen="uploadloading" :element-loading-text="$t('tipsInfo.uploading')"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="listHeader">
			<i></i><span>{{$t('cwhInboundList.title')}}</span>
		</div>
		<el-form style="margin:10px 0 0 0" :model="queryParams" ref="queryParams" :inline="true"
			label-width="auto" @submit.native.prevent>
			<el-form-item label prop="orderNo">
				<el-input v-model.trim="queryParams.orderNo" clearable
					:placeholder="$t('cwhInboundList.inboundNo')" style="width: 260px;"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="handleSearch">
					{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button icon="el-icon-refresh" type="primary" @click="getList">
					{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
				</el-button>
				<el-button icon="el-icon-plus" type="primary" @click="handleCreate">
					{{$t('commonInfo.createBtn')}}</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" ref="listTable" border style="width: 100%;font-size: 16px;"
			v-loading="loading">
			<el-table-column :label="$t('cwhInboundList.inboundNo')" prop="orderNo" min-width="160"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.packageAmount')" prop="packageAmount" min-width="100"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.remark')" prop="remarks" min-width="100"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.statusColumn')" prop="status" min-width="100"
				align="center">
				<template slot-scope="{ row }">
					<span>{{returnStatusName(row.status)}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.initTime')" prop="initTime" align="center" min-width="180">
			</el-table-column>
			<el-table-column :label="$t('commonInfo.operationColumn')" class-name="small-padding fixed-width"
				fixed="right" width="140" align="center" style="padding-right:0">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleCheck(row)"
					icon="el-icon-view">{{$t('commonInfo.actionCheckCarton')}}
					</el-button>
					<br v-if="row.awbUrl != null && row.awbUrl != ''" />
					<el-button v-if="row.awbUrl != null && row.awbUrl != ''" type="text" size="small" @click="handleDownload(row.awbUrl)"
		      icon="el-icon-download">{{$t('commonInfo.downloadBoxStickers')}}
					</el-button>
					<br v-if="row.entryAwbUrl != null && row.entryAwbUrl != ''" />
					<el-button v-if="row.entryAwbUrl != null && row.entryAwbUrl != ''" type="text" size="small" @click="handleDownload(row.entryAwbUrl)"
					icon="el-icon-download">{{$t('cwhInboundList.downloadInboundList')}}
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pageBottom">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		<!-- 创建入库单 -->
		<CreateList :isShow="createPopup"></CreateList>
		<!-- 查看箱列表 -->
		<CheckCartonList :isShow="checkCartonDetailPopup" :rowId="rowId"></CheckCartonList>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	import CreateList from './createList.vue';
	import CheckCartonList from './checkCartonList.vue';
	import {
		formatDate
	} from "@/utils/index";
	export default {
		name:'CWHInboundList',
		components: {
			CreateList,
			CheckCartonList
		},
		data() {
			return {
				uploadloading:false,
				queryParams: {
					page: 1,
					limit: 10,
					orderNo:'',
				},
				tableData: [],
				loading: false,
				total: 0,
				
				// 创建
				createPopup:false,
				
				// 查看箱列表
				rowId:'',
				checkCartonDetailPopup:false,
			}
		},
		//方法集合
		methods: {
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getList();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getList();
			},
			// 搜索空运订单
			handleSearch() {
				this.getList();
			},
			// 重置
			handleReset() {
				this.queryParams = {
					page: 1,
					limit: 10,
					orderNo:'',
				}
				this.getList();
			},
			// 获取列表
			async getList() {
				this.loading = true;
				this.tableData = [];
				const res = await this.$http.post("/toborder/cwh/entry/page", this.queryParams);
				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					data.forEach(item => {
						item.initTime = formatDate(item.initTime);
					});
					this.tableData = data;
					this.loading = false;
				} else {
					this.$message.error(res.msg);
					this.loading = false;
				}
			},
			// 创建入库单
			handleCreate(){
				this.createPopup = true;
			},
			// 返回状态名称
			returnStatusName(status){
				let name = '';
				switch(status){
					case 100: name = this.$t('cwhInboundList.createdStatus'); break;
					case 150: name = this.$t('cwhInboundList.inboundingStatus'); break;
					case 200: name = this.$t('cwhInboundList.inboundedStatus'); break;
					case 300: name = this.$t('cwhInboundList.outboundedStatus'); break;
				}
				return name;
			},
			// 下载链接
			handleDownload(url){
				window.open(url);
			},
			// 查看箱列表
			handleCheck(row){
				this.rowId = row.orderNo;
				this.checkCartonDetailPopup = true;
			},
		},
		created() {
			this.getList();
		},
	}
</script>

<style scoped>
	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	
	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	
	.listHeader span {
		display: inline-block;
		float: left;
	}
	.app-container {
		padding: 10px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
	}
</style>