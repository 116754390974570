import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // 路由重定向
  { path: '/', redirect: '/menu' },
  // 登录
  {
    path: '/login', component: () => import("@/views/login.vue")
  },
	{
	  path: '/changePassword', component: () => import('@/views/changePassword.vue')
	},
	
  {
    path: '/menu', 
		name:'menu',
		component: () => import('@/views/menu.vue'),
    children: [
			// VIP 功能，部门ID：209
			// 首页
      {
        path: '/', 
				name:'/',
				icon:'el-icon-set-up',
				title:'menuItem.homeItem',
				deptId:209,
				meta:{
					noCache:true,
				},
				component: () => import('@/views/home.vue')
      },
			// 空运订单
			{
				path:'',
				name:'airOrder',
				icon:'el-icon-s-promotion',
				title:'menuItem.airOrderItem.name',
				component: () => import('@/views/main.vue'),
				deptId:209,
				meta:{
					noCache:true,
				},
				children:[
					// B2B订单
					{
						path: '/B2BAirOrderList', 
						name:'/B2BAirOrderList',
						icon:'el-icon-tickets',
						title:'menuItem.airOrderItem.b2bOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/China/airOrder/index.vue'),
					},
					// B2C订单
					{
					  path: '/B2COrderList', 
						name:'/B2COrderList',
						icon:'el-icon-tickets',
						title:'menuItem.airOrderItem.b2cOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/China/airOrder/B2COrderList.vue')
					}
				]
			},
			// 海运订单
			{
				path:'/SeaOrder',
				name:'SeaOrder',
				icon:'el-icon-ship',
				title:'menuItem.seaOrderItem.name',
				component: () => import('@/views/main.vue'),
				deptId:209,
				meta:{
					noCache:true,
				},
				children:[
					// 海运散货
					{
						path: '/B2BSeaOrderList', 
						name:'B2BSeaOrderList',
						icon:'el-icon-tickets',
						title:'menuItem.seaOrderItem.bulkCargoOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/China/seaOrder/index.vue')
					}
				]
			},
			// 沙特仓履单
			{
				path:'/KsaWH',
				name:'KsaWH',
				icon:'el-icon-s-home',
				title:'menuItem.ksaWhItem.name',
				component: () => import('@/views/main.vue'),
				deptId:209,
				meta:{
					noCache:true,
				},
				children:[
					// 商品管理
					{
						path: '/merchandiseList', 
						name:'merchandiseList',
						icon:'el-icon-set-up',
						title:'menuItem.ksaWhItem.product',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/Fulfillment/merchandiseList.vue')
					},
					// 批次管理
					{
						path: '/overseaLocation', 
						name:'overseaLocation',
						icon:'el-icon-set-up',
						title:'menuItem.ksaWhItem.batch',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/Fulfillment/overseaLocation.vue')
					},
					// 库存管理
					// {
					// 	path: '/Inventory',
					// 	name:'Inventory',
					// 	icon:'el-icon-set-up',
					// 	title:'menuItem.ksaWhItem.inventory',
					// 	deptId:209,
					// 	meta:{
					// 		noCache:true,
					// 	},
					// 	component: () => import('@/views/Fulfillment/Inventory.vue')
					// },
					{
						path:'/Inventory',
						name:'Inventory',
						icon:'el-icon-tickets',
						title:'menuItem.ksaWhItem.inventory',
						deptId:209,
						component: () => import('@/views/main.vue'),
						meta:{
							noCache:true,
						},
						children:[
							// 商品管理
							{
								path: '/skuManage',
								name:'skuManage',
								icon:'el-icon-tickets',
								title:'menuItem.ksaWhItem.skuManage',
								deptId:209,
								meta:{
									noCache:true,
								},
								component: () => import('@/views/Fulfillment/inventoryManage/skuManage/index.vue')
							},
							// 整箱管理
							{
								path: '/cartonManage',
								name:'cartonManage',
								icon:'el-icon-tickets',
								title:'menuItem.ksaWhItem.cartonManage',
								deptId:209,
								meta:{
									noCache:true,
								},
								component: () => import('@/views/Fulfillment/inventoryManage/cartonManage/index.vue')
							},
						]
					}, 
					
					// FBX订单
					{
						path: '/FBAOrder',
						name:'FBAOrder',
						icon:'el-icon-set-up',
						title:'menuItem.ksaWhItem.fbxOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/Fulfillment/FBAOrder.vue')
					},
					// B2C订单
					{
						path: '/B2COrderImport',
						name:'B2COrderImport',
						icon:'el-icon-set-up',
						title:'menuItem.ksaWhItem.b2cOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/Fulfillment/B2COrderImport.vue')
					},
					// 操作日志
					{
						path:'/OperationLogs',
						name:'OperationLogs',
						icon:'el-icon-tickets',
						title:'menuItem.ksaWhItem.operationLogs',
						deptId:209,
						component: () => import('@/views/main.vue'),
						meta:{
							noCache:true,
						},
						children:[
							// 上架日志
							{
								path: '/inscanLog',
								name:'inscanLog',
								icon:'el-icon-tickets',
								title:'menuItem.ksaWhItem.inboundLogs',
								deptId:209,
								meta:{
									noCache:true,
								},
								component: () => import('@/views/Logs/inscanLog.vue')
							},
							// 下架日志
							{
								path: '/OutscanLog',
								name:'OutscanLog',
								icon:'el-icon-tickets',
								title:'menuItem.ksaWhItem.outboundLogs',
								deptId:209,
								meta:{
									noCache:true,
								},
								component: () => import('@/views/Logs/outscanLog.vue')
							},
						]
					}, 
				]
			},
			// 平台订单
			{
				path:'/PlatformOrder',
				name:'PlatformOrder',
				icon:'el-icon-s-shop',
				title:'menuItem.platformOrderItem.name',
				component: () => import('@/views/main.vue'),
				deptId:209,
				meta:{
					noCache:true,
				},
				children:[
					// 速卖通
					{
						path: '/aliExpress',
						name:'aliExpress',
						icon:'el-icon-tickets',
						title:'menuItem.platformOrderItem.aliExpress',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/platformOrder/aliExpress.vue')
					},
				]
			},
			// 退仓管理
			{
				path:'/Rto',
				name:'Rto',
				icon:'el-icon-menu',
				title:'menuItem.rtoItem.name',
				component: () => import('@/views/main.vue'),
				deptId:209,
				meta:{
					noCache:true,
				},
				children:[
					// B2C订单
					{
						path: '/RtoBind',
						name:'RtoBind',
						icon:'el-icon-tickets',
						title:'menuItem.rtoItem.rtoB2cOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/RTO/RtoBind.vue')
					},
					// FBX订单
					{
						path: '/RtoFbx',
						name:'RtoFbx',
						icon:'el-icon-tickets',
						title:'menuItem.rtoItem.rtoFbxOrder',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/RTO/RtoFbx.vue')
					},
				]
			},
			// 账单管理
			{
				path:'/invoiceManage',
				name:'invoiceManage',
				icon:'el-icon-menu',
				title:'menuItem.invoiceManageItem.name',
				component: () => import('@/views/main.vue'),
				deptId:209,
				meta:{
					noCache:true,
				},
				children:[
					// 仓储费
					{
						path: '/storageCharge',
						name:'storageCharge',
						icon:'el-icon-tickets',
						title:'menuItem.invoiceManageItem.storageChargeItem.name',
						deptId:209,
						meta:{
							noCache:true,
						},
						component: () => import('@/views/main.vue'),
						children:[
							// 费用明细
							{
								path: '/chargeDetail',
								name:'chargeDetail',
								icon:'el-icon-tickets',
								title:'menuItem.invoiceManageItem.storageChargeItem.chargeDetail',
								deptId:209,
								meta:{
									noCache:true,
								},
								component: () => import('@/views/invoiceManage/storageCharge/chargeDetail/index.vue')
							}
						]
						
					},
				],
			},
			// 轨迹查询
			{
				path: '/Tracking',
				name:'Tracking',
				icon:'el-icon-set-up',
				title:'menuItem.trackingItem',
				deptId:209,
				meta:{
					noCache:true,
				},
				component: () => import('@/views/Tracking.vue')
			},
			// 客户信息
			{
				path: '/customerInfo',
				name:'customerInfo',
				icon:'el-icon-set-up',
				title:'menuItem.clientInfoItem',
				deptId:209,
				meta:{
					noCache:true,
				},
				component: () => import('@/views/customerInfo.vue')
			},
			// 模板下载
		  {
		  	path: '/mouldList',
		  	name:'mouldList',
		  	icon:'el-icon-set-up',
		  	title:'menuItem.templateDownloadItem',
				deptId:209,
				meta:{
					noCache:true,
				},
		  	component: () => import('@/views/mouldList.vue')
		  },
			
			
			
			// CWH功能，与原VIP用户功能权限划分 部门ID：208
			// 入库单
			{
				path:'/inboundList',
				name:'inboundList',
				icon:'el-icon-tickets',
				title:'menuItem.cwhInboundListItem',
				deptId:208,
				meta:{
					noCache:true,
				},
				component: () => import('@/views/cwh/inboundList/index.vue'),
			},
			// 库存列表
			{
				path:'/inventoryList',
				name:'inventoryList',
				icon:'el-icon-tickets',
				title:'menuItem.cwhInventoryListItem',
				deptId:208,
				meta:{
					noCache:true,
				},
				component: () => import('@/views/cwh/inventoryList/index.vue'),
			},
			// 出库单
			{
				path:'/outboundList',
				name:'outboundList',
				icon:'el-icon-tickets',
				title:'menuItem.cwhOutboundListItem',
				deptId:208,
				meta:{
					noCache:true,
				},
				component: () => import('@/views/cwh/outboundList/index.vue'),
			}
    ]
  },
	
	
]



const router = new VueRouter({
  routes
})

export default router
